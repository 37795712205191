/* .picture {
  display: flex;
  justify-content: center;
}

#headshot {
    height: 500px;
    width: 400px;

  }

.biocontainer {
  display: flex;
}



/* * {
    box-sizing: border-box;
  } */

 /* body {
    backdrop-filter: blur(5px); 
    background-color: rgb(90, 120, 172); 
    border-radius: 26px; 
    box-shadow: 35px 35px 68px 0px rgba(145, 192, 255, 0.5), inset -8px -8px 16px 0px rgba(145, 192, 255, 0.6), inset 0px 11px 28px 0px rgb(255, 255, 255);
}  */

.bg-pan-bottom {
	-webkit-animation: bg-pan-bottom 8s both;
	        animation: bg-pan-bottom 8s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-9-26 11:59:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bg-pan-bottom
 * ----------------------------------------
 */
 /* @-webkit-keyframes bg-pan-bottom {
  0% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 50% 100%;
  }
}
@keyframes bg-pan-bottom {
  0% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 50% 100%;
  }
} */ 

/* @keyframes fadeInOut {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

body {
  animation: fadeInOut 4s infinite;
  background-image: url('https://i.imgur.com/8KqheYl.png');
  background-size: cover;
} */

body, html {
  height: 100%;
  margin: 0;
  /* overflow: hidden; */
}

body {
  background: none; /* Remove body background */
}

.full-screen-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
}

.threejs-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.login-container {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
}

.login-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-family: monospace;
}

.login-button {
  font-size: 1.5rem;
  font-family: monospace;
}